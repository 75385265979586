import React from 'react';
import MainNavigation from "../components/navigation/MainNavigation";
import Footer from "../components/footer/Footer";
import {Container, Icon, Segment} from "semantic-ui-react";
import {graphql} from "gatsby";
import LayoutContainer from "../container/LayoutContainer";
import SEO from "../components/seo/Seo";
import DesktopContainer from "../container/DesktopContainer";
import PageHeader from "../components/utils/PageHeader";
import styles from "./textpagetempl.module.css";
import MobileTabletContainer from "../container/MobileTabletContainer";
import {Link} from "@reach/router";
import layout from "./layout.module.css";


export default function TextPageTempl({
                                          data,
                                      }) {
    const {markdownRemark} = data;
    const {frontmatter, html} = markdownRemark;
    return (
        <LayoutContainer style={styles.main}>
            <SEO title={frontmatter.title}/>
            <MobileTabletContainer>
                <MainNavigation/>
                    <PageHeader title={frontmatter.title}/>
                    <Container fluid>
                        <Segment style={{marginBottom: '3em'}}>
                            <Container className={styles.container} dangerouslySetInnerHTML={{__html: html}}>
                            </Container>
                        </Segment>
                    </Container>
                <Footer/>
            </MobileTabletContainer>
            <DesktopContainer>
                <div className={layout.pageWrapper}>
                <MainNavigation page={frontmatter.type}/>
                <Container className={styles.container}>
                <PageHeader title={frontmatter.title}/>
                    <Segment className={styles.wrapper}>
                        <Container className={styles.container} dangerouslySetInnerHTML={{__html: html}}>
                        </Container>
                    </Segment>
                    {
                        frontmatter.type === "news-article" &&
                        <p className={styles.backLinkContainer}>
                            <Link to="/news/" className={styles.backLink}>
                                <Icon name={'long arrow alternate left'}/>
                                Zurück zur Newsübersicht
                            </Link>
                        </p>
                    }
                </Container>
                <Footer/>
                </div>
            </DesktopContainer>
        </LayoutContainer>
    );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        type
      }
    }
  }
`;